<template>
  <div class="wrapper presentation-page">
    <hero></hero>
    <vos-metiers></vos-metiers>
    <solution-arago></solution-arago>
    <nos-technologies></nos-technologies>
    <vos-interlocuteurs></vos-interlocuteurs>
    <nous-contacter></nous-contacter>
  </div>
</template>

<script>
import {
  Card,
  Button,
  InfoSection,
  // FormGroupInput,
  // Checkbox,
  // Badge,
} from "@/components";
// import { Carousel, CarouselItem, Tooltip } from "element-ui";
import { Tooltip } from "element-ui";
import Hero from "./sections/Hero";
import VosMetiers from "./sections/VosMetiers";
import SolutionArago from "./sections/SolutionArago";
import NosTechnologies from "./sections/NosTechnologies";
import VosInterlocuteurs from "./sections/VosInterlocuteurs";
import NousContacter from "./sections/NousContacter";

export default {
  name: "presentation",
  bodyClass: "presentation-page",
  components: {
    Hero,
    VosMetiers,
    SolutionArago,
    Card,
    InfoSection,
    NousContacter,
    VosInterlocuteurs,
    NosTechnologies,
    [Button.name]: Button,
    // [Carousel.name]: Carousel,
    // [Checkbox.name]: Checkbox,
    // [CarouselItem.name]: CarouselItem,
    // [FormGroupInput.name]: FormGroupInput,
    // [Tooltip.name]: Tooltip,
  },
};
</script>

<style scoped lang="scss">
.github-buttons {
  display: flex;
  align-content: center;
}
</style>
