/*!

 =========================================================
 * Vue Now UI Kit Pro - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-now-ui-kit-pro
 * Copyright 2023 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//plugins
import NowUIKit from "./plugins/now-ui-kit";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faDatabase as fasDatabase,
  faGlobe as fasGlobe,
  faCalculator as fasCalculator,
  faHandHoldingUsd as fasHandHoldingUsd,
  faLandmark as fasLandmark,
  faWallet as fasWallet,
  faGlobeEurope as fasGlobeEurope,
  faSync as fasSync,
  faChartArea as fasChartArea
} from '@fortawesome/free-solid-svg-icons'
import { faEye as farEye } from '@fortawesome/free-regular-svg-icons'

library.add(fasDatabase)
library.add(farEye)
library.add(fasGlobe)
library.add(fasCalculator)
library.add(fasHandHoldingUsd)
library.add(fasLandmark)
//library.add(fasCity)
library.add(fasWallet)
library.add(fasGlobeEurope)
library.add(fasSync)
library.add(fasChartArea)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;
Vue.use(NowUIKit);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
