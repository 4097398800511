<template>
  <div id="nostechnologies" class="projects-5">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h2 class="title">Nos technologies</h2>
          <h4 class="description">
            Arago est une application web, développée sur la base des technologies Microsoft
          </h4>
          <div class="section-space"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 ml-auto">
          <card type="background" raised class="card-background-product"
            style="background-image: url('img/project8.jpg')">
            <!-- <h2 class="card-title">Microsoft Azure</h2>
              <p class="card-description">
                
              </p> -->
            <img v-lazy="'img/fdcs/arago-computer.png'" />
          </card>
        </div>
        <div class="col-md-5 mr-auto">
          <info-section type="primary" fontAwesomeIcon="database" fontAwesomeIconSize="3x" title="Données"
            description="Vos données sont hébergées sur la plateforme sécurisée dans le cloud Azure de Microsoft qui garantit un taux de disponibilité d'au moins 99,9%." />
          <info-section type="primary" fontAwesomeIcon="globe-europe" fontAwesomeIconSize="3x" title="Hébergement"
            description="Nos serveurs sont localisés exclusivement en France métropolitaine." />
          <info-section type="primary" fontAwesomeIcon="sync" fontAwesomeIconSize="3x" title="Traitement de l'information"
            description="Des technologies avancées sont exploitées pour être adaptées à vos exigences de calculs." />
          <info-section type="primary" fontAwesomeIcon="chart-area" fontAwesomeIconSize="3x" title="Restitution"
            description="Nous mettons à disposition une restitution ergonomique et détaillée des résultats via des outils interactifs de Business Intelligence." />
        </div>
      </div>
      <!-- <hr />
        <div class="row">
          <div class="col-md-5 ml-auto mt-5">
            <info-section
              type="warning"
              icon="now-ui-icons users_single-02"
              title="Work With Any Team"
              description="Whether it’s for work or even the next family vacation, Trello helps your team."
            />

            <info-section
              type="warning"
              icon="now-ui-icons business_chart-bar-32"
              title="A Productivity Platform"
              description="Integrate the apps your team already uses directly into your workflow."
            />

            <info-section
              type="warning"
              icon="now-ui-icons arrows-1_refresh-69"
              title="Always In Sync"
              description="No matter where you are, Trello stays in sync across all of your devices."
            />
          </div>
          <div class="col-md-5 mr-auto">
            <card
              type="background"
              raised
              class="card-background-product"
              style="background-image: url('img/project9.jpg')"
            >
              <h2 class="card-title">Trello lets you work.</h2>
              <p class="card-description">
                Trello’s boards, lists, and cards enable you to organize and
                prioritize your projects in a fun, flexible and rewarding way.
              </p>
              <badge type="neutral">Trello</badge>
            </card>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import { Card, InfoSection, Badge, Button, Tabs, TabPane } from '@/components';

export default {
  components: {
    Card,
    Tabs,
    TabPane,
    Badge,
    InfoSection,
    [Button.name]: Button
  }
}
</script>
<style></style>
