<template>
  <div
    id="nouscontacter"
    class="contactus-1 section-image"
    style="background-image: url('img/fdcs/aragonoir.jpg')"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="title">Nous contacter</h2>
          <h4 class="description">
            Vous désirez plus d'informations sur nos produits ?
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-xs-12">
          <info-section type="primary" icon="now-ui-icons ui-1_email-85">
            <h4 class="info-title">Ecrivez-nous</h4>
            <p class="description">
              <a href="mailto:arago.contact@fdcsystemes.com"
                ><strong>arago.contact@fdcsystemes.com</strong></a
              >
            </p>
          </info-section>
        </div>
        <div class="col-md-4 col-xs-12">
          <info-section type="primary" icon="now-ui-icons tech_mobile">
            <h4 class="info-title">Appelez-nous</h4>
            <p class="description">
              <a href="tel:+33153933830"><strong>+33 1 53 93 38 30</strong></a>
            </p>
          </info-section>
        </div>
        <div class="col-md-4 col-xs-12">
          <info-section type="primary" icon="now-ui-icons location_pin">
            <h4 class="info-title">Notre adresse postale</h4>
            <p class="description">
              30, avenue de l'Op&eacute;ra<br />
              75002 Paris<br />
              France
            </p>
          </info-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Button,
  InfoSection,
  FormGroupInput,
  Checkbox,
} from "@/components";
import { Carousel, CarouselItem } from "element-ui";

export default {
  components: {
    Card,
    InfoSection,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  }
};
</script>