<template>
  <div id="solutionarago" class="features-6 section-image" style="background-image: url('img/bg11.jpg')">
    <div class="container">
      <div class="row">
        <div class="col-md-10 ml-auto mr-auto text-center">
          <h2 class="title">Solution Arago</h2>
          <h4 class="description">
            <strong>Arago est une application web évolutive de FDC Systèmes, dont
              l’ambition est de rendre accessible aux investisseurs les
              meilleures technologies en matière de transparisation et de
              calculs réglementaires.</strong>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info info-horizontal">
            <div class="icon icon-info">
              <font-awesome-icon icon="database" size="3x" />
              <!-- <i class="now-ui-icons design-2_html5"></i> -->
            </div>
            <div class="description">
              <h3 class="info-title">Intégration des données</h3>
              <p>
                <strong>Arago propose un outil d’intégration des données basé sur 2
                  formats d’inventaires :
                  <ul>
                    <li>
                      Inventaires simples pour les portefeuilles de
                      l’institution (positions détenues en direct)
                    </li>
                    <li>
                      Inventaires de fonds au format « TPT », anciennement « Ampère » (transparence
                      des fonds détenus en direct).
                    </li>
                  </ul>
                  Leur intégration est auditée, assurant une intégration fiable
                  des données.
                </strong>
              </p>
            </div>
          </div>
          <div class="info info-horizontal">
            <div class="icon icon-danger">
              <!-- <i class="now-ui-icons design_palette"></i> -->
              <font-awesome-icon :icon="['fas', 'calculator']" size="3x" />
            </div>
            <div class="description">
              <h3 class="info-title">Solvabilité II</h3>
              <p>
                <strong>Définir son périmètre de calcul et ses particularités
                  (participations stratégiques, LTEI..), déterminer ses
                  paramètres de calcul, et générer vos calculs de SCR de marché
                  et vos rapport réglementaires (QRT) sur la base de la formule
                  standard, en quelques clicks.</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="phone-container">
            <img v-lazy="'img/fdcs/arago-medaillon.png'" style="margin-top: 100px" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-horizontal">
            <div class="icon icon-primary">
              <!-- <i class="now-ui-icons design-2_ruler-pencil"></i> -->
              <font-awesome-icon :icon="['far', 'eye']" size="3x" />
            </div>
            <div class="description">
              <h3 class="info-title">Transparence des portefeuilles</h3>
              <p>
                <strong>
                  Un rapport dynamique restitue l’inventaire transparisé des
                  portefeuilles sélectionnés, ainsi que des indicateurs
                  d’exposition permettant une analyse fine du portefeuille. Cet
                  inventaire transparisé est bâti par Arago en substituant à
                  chaque fonds détenu en portefeuille, son inventaire et ce de
                  manière récursive quand un fonds sera présent dans un autre
                  fonds, jusqu’au niveau 5.</strong>
              </p>
            </div>
          </div>
          <div class="info info-horizontal">
            <div class="icon icon-success">
              <!-- <i class="now-ui-icons files_single-copy-04"></i> -->
              <font-awesome-icon :icon="['fas', 'globe']" size="3x" />
            </div>
            <div class="description">
              <h3 class="info-title">Application web</h3>
              <p>
                <strong>Arago est accessible directement sur le web dans un espace
                  sécurisé. Le client dispose d’un (ou plusieurs) compte(s)
                  utilisateur(s) lui permettant de se connecter à son
                  environnement de travail afin d’effectuer ses calculs et
                  analyses.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>