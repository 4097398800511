<template>
  <div id="vosmetiers" class="features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto">
          <h2 class="title">Vos métiers</h2>
          <h4 class="description ">
            Les outils développés par FDC Systèmes s’adressent à tous les
            gestionnaires d’épargne ou de réserves, qu’ils soient soumis ou non
            à la réglementation Solvabilité II.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-primary">
                <font-awesome-icon icon="hand-holding-usd" size="5x"/>
              <!-- <i class="now-ui-icons ui-2_chat-round"></i> -->
            </div>
            <h4 class="info-title">Entités Solvabilité II</h4>
            <p class="description">
              Compagnies et mutuelles d’assurance, institution de prévoyance<br />
              <i
                >« Gagner un temps précieux pour vos calculs réglementaires »</i
              >
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-success">
              <font-awesome-icon icon="landmark" size="5x"/>
              <!-- <i class="now-ui-icons business_chart-pie-36"></i> -->
            </div>
            <h4 class="info-title">Gestionnaires d’actifs</h4>
            <p class="description">
              Multi-gérants, Family-Office<br />
              <i
                >« Analyser vos portefeuilles avec un maximum de granularité
                »</i
              >
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info info-hover">
            <div class="icon icon-warning">
              <!-- <i class="now-ui-icons design-2_ruler-pencil"></i> -->
              <font-awesome-icon icon="wallet" size="5x"/>
            </div>
            <h4 class="info-title">Autres investisseurs institutionnels</h4>
            <p class="description">
              <i
                >« Analyser et suivre les risques de vos portefeuilles par
                transparence »</i
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>