<template>
  <div id="vosinterlocuteurs" class="testimonials-1 section-image" style="background-image: url('img/bg19.jpg')">
    <div class="container">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto text-center">
          <h2 class="title">Vos interlocuteurs</h2>
          <h4 class="description">
            Filiale de la
            <a href="https://www.financieredelacite.com">Financière de la Cité</a>, FDC Systèmes a été constituée dans le
            but d'accompagner les
            investisseurs institutionnels dans le traitement de leurs données
            financières.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <card type="testimonial" no-footer-line>
            <img slot="avatar" class="img img-raised" src="img/equipe/Franck_Languillat.jpg" />
            <p class="card-description">
              Ingénierie financière<br>Spécialiste produits
            </p>
            <div slot="raw-content" class="icon icon-primary">
              <i class="fas fa-quote-right"></i>
            </div>
            <template slot="footer">
              <h4 class="card-title">Franck Languillat</h4>
            </template>
          </card>
        </div>
        <div class="col-md-4">
          <card type="testimonial" no-footer-line>
            <img slot="avatar" class="img img-raised" src="img/equipe/David_Simon.jpg" />
            <p class="card-description">
              Systèmes d'Information<br>&nbsp;
            </p>
            <div slot="raw-content" class="icon icon-primary">
              <i class="fas fa-quote-right"></i>
            </div>
            <template slot="footer">
              <h4 class="card-title">David Simon</h4>
            </template>
          </card>
        </div>
        <div class="col-md-4">
          <card type="testimonial" no-footer-line>
            <img slot="avatar" class="img img-raised" src="img/equipe/Noé_Thorel.jpg" />
            <p class="card-description">
              Spécialiste produits<br>&nbsp;
            </p>
            <div slot="raw-content" class="icon icon-primary">
              <i class="fas fa-quote-right"></i>
            </div>
            <template slot="footer">
              <h4 class="card-title">No&eacute; Thorel</h4>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, Button } from "@/components";
import { Carousel, CarouselItem } from "element-ui";

export default {
  components: {
    Card,
    [Button.name]: Button,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
};
</script>
<style scoped>
.carousel-centered {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>