<template>
  <div class="page-header clear-filter" style="background-image: url('img/fdcs/Arago-Louvres.png')">
    <!-- <div class="rellax-header rellax-header-sky">
        <parallax
          class="page-header-image"
          style="
            background-image: url('img/Arago-Louvres.png');
          "
        >
        </parallax>
      </div> -->
    <!-- <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
        <parallax
          class="page-header-image page-header-city"
          style="
            background-image: url('img/Arago-Louvres.png');
          "
        >
        </parallax>
      </div> -->
    <div class="rellax-text-container rellax-text">
      <h1 class="h1-seo" data-rellax-speed="-2">
        <img src="img/fdcs/logoArago601x135.png" />
      </h1>
      <div class="pro"><img src="img/fdcs/logoFDCS.png"></div>
    </div>
    <h3 class="h3-description rellax-text" data-rellax-speed="-1">
      Simplifier le traitement de vos donn&eacute;es financi&egrave;res<br>Diriger
      efficacement vos obligations r&eacute;glementaires
    </h3>
  </div>
</template>

<script>
export default {};
</script>

<style></style>